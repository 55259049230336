// Generated with tools/create-component.js
import React from "react";
import classNames from "classnames";
import { HeroQueryFragment } from "codegen";
import { Breadcrumbs, CmsContent, Image } from "components";

import "./Hero.scss";

interface HeroProps {
  data: HeroQueryFragment;
  location?: any;
}

export const Hero: React.FC<HeroProps> = ({ data: { image, content, compact, breadcrumbs }, location }) => {
  return (
    <>
      <section
        className={classNames({
          hero: true,
          layout: true,
          "hero--compact": compact,
          "hero--colour": !image,
        })}
      >
        <div className="hero__wrapper">
          <div>
            {breadcrumbs === "above" && <Breadcrumbs breadcrumbs={location} />}
            <CmsContent content={content} />
            {image && <Image src={image} className="hero__background" />}
          </div>
        </div>
      </section>

      {/* Breadcrumbs if they're set to below banner */}
      {breadcrumbs === "below" && (
        <section className="hero__breadcrumbs layout">
          <Breadcrumbs breadcrumbs={location} />
        </section>
      )}
    </>
  );
};
