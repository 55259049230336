// Generated with tools/create-component.js
import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { TestimonialFeedQueryFragment, WpTestimonial_AcftestimonialsFilterInput } from "codegen";
import "./TestimonialFeed.scss";
import { Carousel, Container, CmsContent, Image } from "components";

interface TestimonialContentProps {
  showLogo: boolean;
}

const TestimonialContent: React.FC<TestimonialContentProps> = ({
  data: {
    content,
    acfTestimonials: { logo, reference },
  },
  showlogo,
}) => {
  return (
    <figure className="testimonial-feed__testimonial">
      <blockquote className="testimonial-feed__testimonial__content">
        {showlogo && logo && <Image src={logo} />}
        <CmsContent content={content} />
      </blockquote>
      <figcaption className="testimonial-feed__testimonial__reference">{reference}</figcaption>
    </figure>
  );
};

interface TestimonialFeedProps {
  testimonials: WpTestimonial_AcftestimonialsFilterInput;
}

export const TestimonialFeedComponent: React.FC<TestimonialFeedProps> = ({
  testimonials,
  data: { title, showlogo },
}) => {
  const swiperSettings = {
    id: "testimonial-carousel",
    slidesPerView: 1,
    spaceBetween: 50,
    grabCursor: false,
    pagination: true,
    navigation: true,
    autoplay: {
      delay: 5000,
    },
  };

  return (
    <Container className="testimonial-feed">
      {title && <h2>{title}</h2>}
      <Carousel swiperSettings={swiperSettings}>
        {Object.keys(testimonials).length > 0 &&
          testimonials?.map(({ node }, index) => {
            return <TestimonialContent data={node} key={index} showlogo={showlogo} />;
          })}
      </Carousel>
    </Container>
  );
};

interface TestimonialFeedProps {
  data: TestimonialFeedQueryFragment;
}

export const TestimonialFeed: React.FC<TestimonialFeedProps> = ({ data }) => {
  const staticQuery = useStaticQuery(graphql`
    query TetsimonialQuery {
      allWpTestimonial {
        edges {
          node {
            content
            acfTestimonials {
              reference
              logo {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, width: 200, placeholder: BLURRED)
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  return <TestimonialFeedComponent testimonials={staticQuery?.allWpTestimonial?.edges} data={data} />;
};
