import React from "react";
import { useCPTItems } from "hooks";
import { CptFeedQueryFragment, WpPost } from "codegen";
import { PostCard, CmsContent, Container } from "components";
import { ContainerWidthEnum, BackgroundColourEnum, ContainerMarginEnum } from "utils/types/container";

// import "./cpt-feed.scss";

import "./FeaturedPosts.scss";

interface FeaturedPostsProps {
  data: CptFeedQueryFragment;
  location: unknown;
}

export const FeaturedPosts = ({ data }: FeaturedPostsProps): JSX.Element => {
  const {
    feedContent = "",
    feedTitle = "",
    feedItems = [],
    feedType = "post",
    backgroundColour,
    containerWidth,
    sectionSpacing,
    sectionId,
  } = data;

  return (
    <Container
      className="featured-posts"
      backgroundColour={BackgroundColourEnum[backgroundColour]}
      containerWidth={ContainerWidthEnum[containerWidth]}
      sectionSpacing={ContainerMarginEnum[sectionSpacing]}
      sectionId={sectionId}
    >
      {feedTitle && feedTitle.length > 0 && <CmsContent content={feedTitle} />}
      {feedContent && feedContent.length > 0 && <CmsContent content={feedContent} />}
      <div className="featured-posts__items">
        {Object.keys(feedItems).length > 0 &&
          feedItems?.map((item, index) => {
            return <PostCard item={item} key={`${item.id}-${index}`} />;
          })}
      </div>
    </Container>
  );
};
