export enum ContainerWidthEnum {
  LAYOUT = "layout",
  LAYOUT_SMALL = "layout--small",
  FULL_BLEED = "full-bleed",
}

export enum ContainerMarginEnum {
  SECTION = "section",
  SECTION_SMALL = "section--small",
  SECTION_LARGE = "section--large",
  SECTION_NONE = "section--none",
}

export enum BackgroundColourEnum {
  NONE = "none",
  PRIMARY = "primary",
  SECONDARY = "secondary",
  TERTIARY = "tertiary",
}
