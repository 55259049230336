import React, { useState } from "react";
import { Filter, PostCard, Container, Pagination } from "components";
import { PostListQueryFragment, WpCategory, WpPost, WpProject, WpProject_Category } from "codegen";
import { ContainerWidthEnum, BackgroundColourEnum, ContainerMarginEnum } from "utils/types/container";
import "./PostList.scss";

interface PostListProps {
  data?: PostListQueryFragment & { numberOfPages: number; currentPageNumber: number };
  postListItems?: (WpPost | WpProject)[] & any;
  postListCategories?: (WpCategory | WpProject_Category)[] & any;
  location?: unknown;
  browserLocation: Location;
  filterType?: "dropdown" | "searchBar";
}

export const PostList = ({
  data = {
    postType: "post",
    __typename: "WpContentNode_Acflayout_Layout_PostList",
    currentPageNumber: 1,
    numberOfPages: 1,
  },
  postListItems = undefined,
  postListCategories = undefined,
  filterType = "searchBar",
  browserLocation,
}: PostListProps): JSX.Element => {
  const { backgroundColour, containerWidth, sectionSpacing, sectionId, currentPageNumber, numberOfPages } = data;

  const [filteredItems, setFilteredItems] = useState(postListItems);

  return (
    <Container
      className="post-list"
      backgroundColour={BackgroundColourEnum[backgroundColour]}
      containerWidth={ContainerWidthEnum[containerWidth]}
      sectionSpacing={ContainerMarginEnum[sectionSpacing]}
      sectionId={sectionId}
    >
      {postListItems && postListCategories && (
        <>
          <Filter
            categories={postListCategories}
            filterType={filterType}
            contentToSearch={postListItems}
            setSearchResults={setFilteredItems}
          />
          <div className="post-list__grid">
            {Object.keys(filteredItems).length > 0 &&
              filteredItems?.map((item) => {
                return <PostCard item={item} key={item.id} />;
              })}
          </div>
        </>
      )}
      <Pagination currentPageNumber={currentPageNumber} numberOfPages={numberOfPages} location={browserLocation} />
    </Container>
  );
};
