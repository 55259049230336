import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { TeamProfilesQueryFragment } from "codegen";
import { CmsContent, Container, SocialsLinks, Image } from "components";
import { ContainerWidthEnum, BackgroundColourEnum, ContainerMarginEnum } from "utils/types/container";

import "./TeamProfiles.scss";

interface TeamProfilesProps {
  data: TeamProfilesQueryFragment;
}

export const TeamProfiles = ({ data }: TeamProfilesProps): JSX.Element => {
  const {
    heading = "",
    content = "",
    profiles = [],
    backgroundColour,
    containerWidth,
    sectionSpacing,
    sectionId,
  } = data;
  if (profiles.length > 0) {
    return (
      <Container
        className="team-profiles"
        backgroundColour={BackgroundColourEnum[backgroundColour]}
        containerWidth={ContainerWidthEnum[containerWidth]}
        sectionSpacing={ContainerMarginEnum[sectionSpacing]}
        sectionId={sectionId}
      >
        {heading.length > 0 && <h2>{heading}</h2>}
        {content.length > 0 && <CmsContent content={content} />}
        <div className="team-profiles__grid">
          {Object.keys(profiles).length > 0 &&
            profiles?.map((profile, index) => {
              return (
                <div className="team-profiles__profile" key={`team-${index}`}>
                  <div className="team-profiles__profile__image">
                    {profile.image && <Image src={profile.image} alt={profile.name} />}
                  </div>
                  <h3>{profile.name}</h3>
                  <p>
                    <strong>{profile.position}</strong>
                  </p>
                  <div className="team-profiles__profile__socials">
                    {profile.socials && <SocialsLinks passedData={profile.socials} />}
                  </div>
                  {profile.bio.length > 0 && <CmsContent content={profile.bio} />}
                </div>
              );
            })}
        </div>
      </Container>
    );
  }

  return null;
};
