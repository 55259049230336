import React, { useMemo } from "react";

import {
  Hero,
  PostList,
  ContactFormBlock,
  FeaturedPosts,
  TeamProfiles,
  TestimonialFeed,
  FlexibleBlock,
} from "../components";
import { LoaderProps } from "./props";

/** Simple component to load components based on ACF data */
export const Loader = ({
  data,
  location,
  postListCategories,
  postListItems,
  browserLocation,
}: LoaderProps): JSX.Element => {
  const acfComponents = useMemo(
    () =>
      data
        .filter((item) => item)
        .map((item, index) => {
          if (!item || item.fieldGroupName === undefined) {
            return null;
          }

          if (item.fieldGroupName.includes("Hero")) {
            return <Hero key={`${index}_Hero`} data={item} location={location} />;
          }

          if (item.fieldGroupName.includes("ContactFormBlock")) {
            return <ContactFormBlock key={`${index}_ContactFormBlock`} data={item} />;
          }

          if (item.fieldGroupName.includes("PostList")) {
            return (
              <PostList
                key={`${index}_PostList`}
                data={item}
                browserLocation={browserLocation}
                location={location}
                postListCategories={postListCategories}
                postListItems={postListItems}
              />
            );
          }

          if (item.fieldGroupName.includes("TestimonialFeed")) {
            return <TestimonialFeed key={`${index}_TestimonialFeed`} data={item} />;
          }

          if (item.fieldGroupName.includes("FeaturedPosts")) {
            return <FeaturedPosts key={`${index}_FeaturedPosts`} data={item} location={location} />;
          }

          if (item.fieldGroupName.includes("TeamProfiles")) {
            return <TeamProfiles key={`${index}_TeamProfiles`} data={item} />;
          }

          if (item.fieldGroupName.includes("FlexibleBlock")) {
            return <FlexibleBlock key={`${index}FlexibleBlock`} data={item} />;
          }

          return null;
        }),
    [data],
  );

  return <>{acfComponents}</>;
};
