// Generated with tools/create-component.js
import React from "react";
import { ContactFormBlockQueryFragment } from "codegen";
import { ColumnSwitch, Container } from "components";
import "./ContactFormBlock.scss";
import { ContainerWidthEnum, BackgroundColourEnum, ContainerMarginEnum } from "utils/types/container";

interface ContactFormBlockProps {
  data: ContactFormBlockQueryFragment;
}

export const ContactFormBlock: React.FC<ContactFormBlockProps> = ({
  data: { row, backgroundColour, containerWidth, sectionSpacing, sectionId },
}) => {
  return (
    <Container
      className="contact-form-block"
      backgroundColour={BackgroundColourEnum[backgroundColour]}
      containerWidth={ContainerWidthEnum[containerWidth]}
      sectionSpacing={ContainerMarginEnum[sectionSpacing]}
      sectionId={sectionId}
    >
      {row &&
        Object.keys(row).length > 0 &&
        row?.map((column, index) => {
          return <ColumnSwitch data={column} key={index} />;
        })}
    </Container>
  );
};
